export const LOCALES = {
  en_HK: {
    default: true,
    subLocales: [
      'en_AE', 'en_AU', 'en_BD', 'en_BE', 'en_BH', 'en_CA', 'en_CN', 'en_DK', 'en_GB',
      'en_HK', 'en_ID', 'en_IE', 'en_IL', 'en_IN', 'en_JP', 'en_KH', 'en_KR', 'en_LK',
      'en_MM', 'en_MV', 'en_MY', 'en_NP', 'en_NZ', 'en_PH', 'en_SA', 'en_SG', 'en_TH',
      'en_TW', 'en_US', 'en_VN', 'en_ZA'
    ],
    content: {
      selected: {
        key: 'locale_en_HK_selected',
        default: 'English',
      },
      selection: {
        key: 'locale_en_HK_selection',
        default: 'English',
      },
    },
  },
  zh_HK: {
    subLocales: ['zh_TW'],
    content: {
      selected: {
        key: 'locale_zh_HK_selected',
        default: 'Hong Kong SAR | 繁體中文',
      },
      selection: {
        key: 'locale_zh_HK_selection',
        default: 'Hong Kong SAR | 繁體中文',
      },
    },
  },
  sc_CN: {
    content: {
      selected: {
        key: 'locale_sc_CN_selected',
        default: 'Simplified Chinese',
      },
      selection: {
        key: 'locale_sc_CN_selection',
        default: 'Simplified Chinese',
      },
    },
  },
  ja_JP: {
    content: {
      selected: {
        key: 'locale_ja_JP_selected',
        default: 'Japanese',
      },
      selection: {
        key: 'locale_ja_JP_selection',
        default: 'Japanese',
      },
    },
  },
};
