import CreditCardSchemeAbstraction from '../credit-card-scheme-abstraction.js';

class Eftpos extends CreditCardSchemeAbstraction {
  static schemeName = 'eftpos';

  constructor(
    inputMask = '#### #### #### ####',
    cardNumberLength = 16,
    inputMaskDelimiter = ' ',
  ) {
    super(inputMask, cardNumberLength, inputMaskDelimiter);
  }

  liveDetectScheme(partialCardNumber) {
    return /^(6\d{0,15}|5[08]\d{0,14})$/.test(partialCardNumber);
  }

  detectScheme(cardNumber) {
    return /^(6\d{15}|5[08]\d{14})$/.test(cardNumber);
  }
}

export default Eftpos;
