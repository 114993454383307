<script setup>
  import { computed, inject, ref, resolveComponent } from 'vue';
  import { useUtility } from '@/composables/use-utility.js';
  const { getDynamicComponent, convertToPascalCase } = useUtility();

  const props = defineProps({
    showUnlinkBtn: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Object,
      required: true,
    },
  });

  const modalManager = inject('modalManager');

  const unlinkCardButton = ref(null);
  const TheModalRemoveCardAction = resolveComponent('TheModalRemoveCardAction');

  const activeLogoComponent = computed(() =>
    props.card.type !== null
      ? getDynamicComponent(`Logo${convertToPascalCase(props.card.type)}`)
      : null,
  );

  const isUnlinkShowBtn = computed(() =>
    props.showUnlinkBtn ? 'show-unlink-btn-card' : '',
  );

  async function openUnlinkCardModal() {
    await modalManager.openModal(TheModalRemoveCardAction, {
      props: { card: props.card },
      focusedElementRef: unlinkCardButton.value?.$el,
    });
  }
</script>

<template>
  <base-card class="linked-card" :class="[isUnlinkShowBtn]">
    <div class="cardnumber-wrapper">
      <component :is="activeLogoComponent" />
      <p class="cardnumber">**** **** **** {{ card.card_pan_last_4 }}</p>
    </div>

    <button-link
      ref="unlinkCardButton"
      class="unlink-card-button"
      @click="openUnlinkCardModal"
      v-if="showUnlinkBtn"
    >
      <icon-trashcan class="unlink-icon" />

      {{ $translation('card_linked_unlink_card_button_label', 'Unlink card') }}

      <span class="visually-hidden">
        <!-- Whitespace necessary to ensure proper spacing for screen readers -->
        &nbsp;
        {{ `**** ${card.card_pan_last_4}` }}
      </span>
    </button-link>
  </base-card>
</template>

<style lang="scss" scoped>
  .linked-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 110px;

    &.show-unlink-btn-card {
      height: 150px;
    }

    @include md-breakpoint {
      max-width: 440px;
    }

    .cardnumber-wrapper {
      display: flex;
      gap: 20px;
      padding-inline-start: 2px;

      :deep(.scheme-logo) {
        &:not(.eftpos-scheme-logo) {
          height: 32px;
        }
      }

      .cardnumber {
        font-size: 1.375em;
        line-height: 32px;
        font-family: $font-regular-heading;
        margin: 0;
      }
    }

    .unlink-card-button {
      gap: 4px;

      .unlink-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
</style>
