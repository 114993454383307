import CreditCardDetector from './src/credit-card-detector.js';
import CreditCardSchemeAbstraction from './src/credit-card-scheme-abstraction.js';

// Importing specific card schemes.
import Visa from './src/schemes/visa.js';
import VisaDankort from './src/schemes/visa-dankort.js';
import MasterCard from './src/schemes/mastercard.js';
import Amex from './src/schemes/amex.js';
import Dankort from './src/schemes/dankort.js';
import Eftpos from './src/schemes/eftpos.js';

// Method to get available card schemes in plugin.
const getSchemes = () => {
  return {
    Visa,
    MasterCard,
    Amex,
    Dankort,
    VisaDankort,
    Eftpos,
  };
};

const creditCardDetector = new CreditCardDetector();

export { getSchemes, creditCardDetector, CreditCardSchemeAbstraction };
