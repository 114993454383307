import CreditCardSchemeAbstraction from '../credit-card-scheme-abstraction.js';

class Dankort extends CreditCardSchemeAbstraction {
  static schemeName = 'nets-storebox';

  constructor(
    inputMask = '#### #### #### ####',
    cardNumberLength = 16,
    inputMaskDelimiter = ' ',
  ) {
    super(inputMask, cardNumberLength, inputMaskDelimiter);
  }

  liveDetectScheme(partialCardNumber) {
    return /^(5|50|501|5019\d{0,12})$/.test(partialCardNumber);
  }

  detectScheme(fullCardNumber) {
    return /^5019\d{12}$/.test(fullCardNumber);
  }
}

export default Dankort;
