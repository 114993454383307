import CreditCardSchemeAbstraction from '../credit-card-scheme-abstraction.js';

class Amex extends CreditCardSchemeAbstraction {
  static schemeName = 'amex-sop';

  constructor(
    inputMask = '#### ###### #####',
    cardNumberLength = 15,
    inputMaskDelimiter = ' ',
  ) {
    super(inputMask, cardNumberLength, inputMaskDelimiter);
  }

  liveDetectScheme(partialCardNumber) {
    return /^(3|3[47]\d{0,13})$/.test(partialCardNumber);
  }

  detectScheme(cardNumber) {
    return /^3[47]\d{13}$/.test(cardNumber);
  }
}

export default Amex;
